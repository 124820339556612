<template>
  <b-card class="shadow-lg border-primary">
    <b-card-body class="d-flex justify-content-between align-items-center p-0">
      <div class="truncate">
        <div class="d-flex">
          <div class="d-flex flex-column align-items-center">
            <h2>{{ displayMinutes }}</h2>
            <span>Minutes</span>
          </div>
          <span>:</span>
          <div class="d-flex flex-column align-items-center">
            <h2>{{ displaySeconds }}</h2>
            <span>Seconds</span>
          </div>
        </div>
      </div>
      <b-avatar variant="light-primary" size="45">
        <feather-icon size="21" icon="CrosshairIcon" />
      </b-avatar>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BAvatar } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardBody,
    BAvatar,
  },
  data() {
    return {
      displayMinutes: 0,
      displaySeconds: 0,
    };
  },
  computed: {
    HoursView() {
      return this.minutesView * 60;
    },
    minutesView() {
      return this.secondsView * 60;
    },
    secondsView: () => 1000,
  },
  mounted() {
    this.showRemaining();
  },
  methods: {
    showRemaining() {
      const end = new Date();
      end.setMinutes(end.getMinutes() + 10);
      const timer = setInterval(() => {
        const now = new Date();

        const distance = end.getTime() - now.getTime();

        if (distance < 0) {
          clearInterval(timer);
          this.$emit('finishedTime')
          return;
        }
        const minutes = Math.floor(
          (distance % this.HoursView) / this.minutesView
        );
        const seconds = Math.floor(
          (distance % this.minutesView) / this.secondsView
        );

        this.displayMinutes = this.formatNum(minutes);
        this.displaySeconds = this.formatNum(seconds);
      }, 1000);
    },
    formatNum: (num) => (num < 10 ? "0" + num : num),
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
.shadow-custom {
  box-shadow: 0 1rem 3rem rgba($gray-300, 0.831) !important;
}
</style>