<template>
  <div>
    <form-wizard
      :color="color"
      :title="null"
      :subtitle="null"
      shape="square"
      class="mb-3"
    >
      <Counter class="w-25" @finishedTime="closeCash" />
      <!-- accoint details tab -->
      <tab-content
        title="Items per consultation"
        :before-change="validationForm"
      >
        <validation-observer ref="accountRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Stats Visit</h5>
              <small class="text-muted"> See all your visit types. </small>
            </b-col>
            <b-col md="12">
              <vue-apex-charts
                type="donut"
                height="300"
                :options="visits.chartOptions"
                :series="visits.series"
              />
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- personal details tab -->
      <tab-content title="Money" :before-change="validationFormInfo">
        <validation-observer ref="infoRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Earnings report</h5>
              <small class="text-muted"
                >enter money received by cash method</small
              >
            </b-col>
            <b-col md="6">
              <b-form-group label="Cash" label-for="cash">
                <validation-provider
                  #default="{ errors }"
                  name="Cash"
                  rules="required"
                >
                  <b-form-input
                    id="cash"
                    v-model="cashClosing.cash"
                    :state="errors.length > 0 ? false : null"
                    placeholder="500"
                    class="form-control"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <tab-content title="Review">
        <validation-observer ref="" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Earnings report</h5>
              <small class="text-muted"
                >enter money received by cash method</small
              >
            </b-col>
            <b-col md="6">
              <b-form-group label="Observation" label-for="observatiom">
                <validation-provider
                  #default="{ errors }"
                  name="Observation"
                  rules="required"
                >
                  <textarea
                    name="observatiom"
                    id="observatiom"
                    class="form-control"
                    v-model="cashClosing.observation"
                    :state="errors.length > 0 ? false : null"
                  ></textarea>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Signature" label-for="signature">
                <div class="d-flex">
                  <VueSignaturePad
                    id="signature"
                    width="100%"
                    height="20vh"
                    ref="signaturePad"
                    class="border border-primary rounded"
                    :options="{
                      onBegin: () => {
                        $refs.signaturePad.resizeCanvas();
                      },
                    }"
                  />
                  <div class="align-self-center">
                    <b-button
                      class="btn-icon align-self-center mx-1 cursor-pointer"
                      variant="danger"
                      @click="clearSignature"
                    >
                      <feather-icon icon="TrashIcon" size="16" />
                    </b-button>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <!-- Footer Template -->
      <template slot="footer" slot-scope="props">
        <div class="wizard-footer-left">
          <wizard-button
            v-if="props.activeTabIndex > 0 && !props.isLastStep"
            @click.native="props.prevTab()"
            :style="props.fillButtonStyle"
            >{{ $t("Form.Previous") }}</wizard-button
          >
        </div>
        <div class="wizard-footer-right">
          <wizard-button
            v-if="!props.isLastStep"
            @click.native="props.nextTab()"
            class="wizard-footer-right"
            :style="props.fillButtonStyle"
            >{{ $t("Form.Next") }}</wizard-button
          >

          <wizard-button
            v-else
            @click.native="closeCash"
            class="wizard-footer-right finish-button d-flex"
            :disabled="sendingForm"
            :style="props.fillButtonStyle"
          >
            <span v-if="!sendingForm">{{ $t("Form.Submit") }}</span>
            <span v-else>
              <SpinnerLoading />
            </span>
          </wizard-button>
        </div>
      </template>
    </form-wizard>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import { FormWizard, TabContent, WizardButton } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";

import { $themeColors } from "@themeConfig";
import VueApexCharts from "vue-apexcharts";

import axiosC from "@/services/cash-account";
import axiosEC from "@/services/external-settings/images";

import Counter from "./Counter";
import ToastNotification from "@/components/ToastNotification";
import NotificationMixin from "@/mixins/NotificationMixin";
import SpinnerLoading from "@/components/SpinnerLoading";
import { convertirBase64AFile } from "@/mixins/Base64ToFile";
export default {
  components: {
    Counter,
    ToastNotification,

    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    WizardButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    BFormInvalidFeedback,
    VueApexCharts,
    SpinnerLoading,
  },
  mixins: [NotificationMixin],
  mounted() {
    this.getData();
  },
  data() {
    return {
      color: $themeColors.primary,
      visits: {
        series: [],
        chartOptions: {
          chart: {
            fontFamily: "Montserrat",
            toolbar: {
              show: true,
              tools: {
                download: false,
              },
            },
          },
          labels: [],
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "12px",
              fontFamily: "Montserrat",
              colors: ["white"],
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  value: {
                    show: true,
                    color: $themeColors.primary,
                  },
                  total: {
                    show: true,
                    showAlways: true,
                    color: $themeColors.primary,
                    label: "Total",
                    formatter(w) {
                      return w.globals.seriesTotals.reduce((a, b) => {
                        return a + b;
                      }, 0);
                    },
                  },
                },
              },
            },
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          stroke: { width: 0 },
          colors: [
            $themeColors.primary,
            $themeColors.warning,
            $themeColors.danger,
          ],
        },
      },
      visitDate: null,
      cashClosing: {
        cash: 0,
        observation: null,
      },
      sendingForm: false,
    };
  },
  methods: {
    async closeCash() {
      this.sendingForm = true;
      const { cash, observation } = this.cashClosing;
      const savePhoto = this.$refs.signaturePad;

      if (savePhoto && !savePhoto.isEmpty()) {
        const { data } = savePhoto.saveSignature();
        const dataPhoto = convertirBase64AFile(data, "miImagen.jpg");
        const photoUrl = await this.uploadPhoto(dataPhoto);
        const dataForm = {
          observation,
          register: cash,
          signature: photoUrl,
        };
        axiosC.cashGlobalClose(dataForm).then((res) => {
          const { type, message } =
            NotificationMixin.methods.notificationCheck(res);
          this.$refs.toast[type](message);
          this.sendingForm = false;
          this.$router.go(-1);
        });
      } else {
        this.$refs.toast.danger("Signature is required");
        this.sendingForm = false;
      }
    },
    async getData() {
      await axiosC.cashGlobalList().then(({ registro }) => {
        this.visitDate = registro.visits;
      });

      let data = {
        nombre: [],
        cantidad: [],
        colores: [],
      };

      this.visitDate.types.forEach((item) => {
        data.nombre.push(item.name);
        data.cantidad.push(item.sum);
        data.colores.push(item.color);
      });

      this.visits = {
        series: data.cantidad,
        chartOptions: {
          labels: data.nombre,
          colors: data.colores,
        },
      };
    },
    async uploadPhoto(file) {
      const formData = new FormData();
      formData.append("type", "DAILY_CLOSING");
      formData.append("file", file);
      const { dir_img } = await axiosEC.createImage(formData);
      return dir_img;
    },
    clearSignature() {
      this.$refs.signaturePad.clearSignature();
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
